import React from 'react';
import reactHtmlParser from 'react-html-parser';
import { graphql, Link } from 'gatsby';
import { Layout, ContactForm } from '../components';
import Img from 'gatsby-image';

const renderLink = (link, ctaText) => (
    <div className="program-cta"><a href={link} className="button large primary">{ctaText}</a></div>
);

export default ({ data: { allMarkdownRemark: { edges }, markdownRemark: { frontmatter: { path: currentPath, image, program, title, externalLink, ctaText }, html } } }) => {
    return (
        <Layout pageTitle={title} showHeader activeTab={'programs'}>
            <div id="wrapper" className="program-page">
                <section id="main" className="wrapper">
                    <div className="inner">
                        <div className="program-content">
                            <h2 className="major">{title}</h2>
                            { image && <Img fluid={image.childImageSharp.fluid} alt={title} /> }
                            {reactHtmlParser(html)}
                            <hr />
                            {externalLink ? renderLink(externalLink, ctaText) : <ContactForm program={program} /> }
                        </div>
                        <div className="other-programs">
                            <h3>Programs</h3>
                            <ul>
                                {edges.map(({ node: { id, frontmatter } }) => (
                                    <li key={id}>
                                        {frontmatter.path === currentPath
                                            ? <span className="active">{frontmatter.title}</span>
                                            : <Link to={frontmatter.path}>{frontmatter.title}</Link>}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </section>
            </div>
        </Layout>
    );
}

export const articleQuery = graphql`
  query($path: String!) {

    markdownRemark(frontmatter: { path: { eq: $path } }) {
        html
        frontmatter {
            program
            title
            path
            externalLink
            ctaText
            image {
                childImageSharp {
                    fluid(maxWidth: 700) {
                        ...GatsbyImageSharpFluid_noBase64
                    }
                }
            }
        }
    }

    allMarkdownRemark(filter: {frontmatter: {template: {eq: "program"}}}) {
        edges {
            node {
                id
                frontmatter {
                    title
                    path
                }
            }
        }
    }
  }
`